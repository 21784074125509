import { useState, useRef, useEffect, useCallback } from 'react';

function AudioPlayer ({ artistAlbumKeys, albumSelection, setAlbumSelection, songSelection, setSongSelection, currentSongList}) {
    const audioRef = useRef();
    const progressBarRef = useRef();
    const [songProgress, setSongProgress] = useState(0);
    const [songDuration, setSongDuration] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    
    const currentAlbumPosition = artistAlbumKeys.indexOf(albumSelection);
    const lastAlbum = artistAlbumKeys.length;

    const getDuration = () => {
        const seconds = audioRef.current.duration;
        setSongDuration(seconds);
        audioRef.current.max = seconds;
    }

    const handleEnd = () => {
        if (songSelection < currentSongList.length - 1) {
            setSongSelection(songSelection + 1);
            setIsLoaded(false);
        }
    };

    const handleLoadedData = () => {
      setIsLoaded(true);
      audioRef.current.play();
    };

    const previousAlbum = () => {
        if (currentAlbumPosition !== 0) {
            setSongSelection(0);
            setAlbumSelection(artistAlbumKeys[currentAlbumPosition - 1]);
        }
    }

    const nextAlbum = () => {
        if (currentAlbumPosition !== lastAlbum - 1) {
            setSongSelection(0);
            setAlbumSelection(artistAlbumKeys[currentAlbumPosition + 1]);
        }
    }
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const playAnimationRef = useRef();

    const repeat = useCallback(() => {
        const currentTime = audioRef?.current?.currentTime;
        setSongProgress(currentTime);
        if (currentTime) {
            progressBarRef.current.value = currentTime;
            progressBarRef.current.style.setProperty(
                '--range-progress:',
                `${progressBarRef.current.value}%`
            );
        }
      
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [audioRef, progressBarRef, setSongProgress]);

    useEffect(() => {
        if (isPlaying) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [isPlaying, audioRef, repeat]);

    const playSong = () => {
        audioRef.current.play().catch(error => {console.error('Autoplay failed:', error)});
        setIsPlaying(true);
    }

    const pauseSong = () => {
        audioRef.current.pause()
        setIsPlaying(false);
    }

    const togglePlayPause = () => {
        if (audioRef.current.paused) {
            playSong();
        } else {
            pauseSong();
        }
    }

    const previousSong = () => {
        if (songSelection > 0) {
            setSongSelection(songSelection - 1);
        }
        if (audioRef.current.paused) {
            playSong();
        };
    }
    
    const rewind = () => {
        audioRef.current.currentTime -= 10;
    }

    const fastForward = () => {
        audioRef.current.currentTime += 10;
    }

    const nextSong = () => {
        if (songSelection < currentSongList.length - 1) {
            setSongSelection(songSelection + 1);
        };  
        if (audioRef.current.paused) {
            playSong();
        };
    };

    const toggleMute = () => {
        if (audioRef.current.muted) {
            audioRef.current.muted = false;
            setIsMuted(false);
        } else {
            audioRef.current.muted = true;
            setIsMuted(true);
        }
    };

    const volume = (value) => {
        audioRef.current.volume = value;
    };

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
          const minutes = Math.floor(time / 60);
          const formatMinutes =
            minutes < 10 ? `0${minutes}` : `${minutes}`;
          const seconds = Math.floor(time % 60);
          const formatSeconds =
            seconds < 10 ? `0${seconds}` : `${seconds}`;
          return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    // Update the progress bar
    const updateProgress = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
    };

    return (
        <div className="player">
            <div className="cover">
                <div className="album-controls">
                    <button className="flatButton" style={{opacity: (currentAlbumPosition !== 0) ? "100%" : "0%"}} onClick={previousAlbum}><small>previous album</small></button>
                </div>
                <div className="album-cover">
                    <img id="cover-img" src={`./music/${currentSongList[songSelection].location}/${currentSongList[songSelection].cover}`} alt="Album cover" />
                </div>
                <div  className="album-controls">
                    <button className="flatButton" style={{opacity: (currentAlbumPosition < lastAlbum - 1) ? "100%" : "0%"}} onClick={nextAlbum}><small>next album</small></button>
                </div>
            </div>            
            <div className="info">
                <div className="labels">
                    <p><small>Track: </small></p>
                    <h2><small>Song: </small></h2>
                    <h3><small>Album: </small></h3>
                    <h4><small>Artist: </small></h4>
                </div>
                <div className="details">
                    <p><small>{currentSongList[songSelection].track}</small></p>
                    <h2>{currentSongList[songSelection].song}</h2>
                    <h3>{currentSongList[songSelection].album}</h3>
                    <h4>{currentSongList[songSelection].artist}</h4>
                </div>
            </div>
            <div className="controls">
                <button className="control-buttons" id="previous-icon" onClick={previousSong}>
                    <img src=".\images\previous.png" alt="Previous" className="playback-controls"/>
                </button>
                <button className="control-buttons" id="rewind" onClick={rewind}>
                    <img src=".\images\rewind.png" alt="Rewind" className="playback-controls"/>
                </button>
                <button className="control-buttons" id="play-icon" onClick={togglePlayPause}>
                    {audioRef?.current?.paused ? <img src=".\images\play.png" alt="Play" className="playback-controls"/> : <img src=".\images\pause.png" alt="Pause" className="playback-controls"/>}
                </button>
                <button className="control-buttons" id="fastforward" onClick={fastForward}>
                    <img src=".\images\fast_forward.png" alt="Fast Forward" className="playback-controls"/>
                </button>
                <button className="control-buttons" id="forward-icon" onClick={nextSong}>
                    <img src=".\images\next.png" alt="Next" className="playback-controls"/>
                </button>
            </div>
            <div className="volume">
                <button id="mute" onClick={toggleMute}>
                    {isMuted ? <img src=".\images\mute.png" alt="Mute" className="button-images"/>:<img src=".\images\speaker.png" alt="Unmute" className="button-images"/>}
                </button>
                <input type="range" id="volume-bar" min="0" max="1" step="0.01" onChange={(e) => volume(e.target.value)} style={{
                        background: `linear-gradient(to right, #f50 ${volume}%, #ccc ${volume}%)`,
                    }}>
                </input>  
                <button id="download">
                    {audioRef.current ? <a href={audioRef.current.currentSrc} download><img src=".\images\download.png" alt="Download" className="button-images"></img></a> : null}
                </button> 
            </div>
            <div className="progress">
                <span>{formatTime(songProgress)}  </span>
                <div className="progress-bar">
                <input type="range" ref={progressBarRef} defaultValue="0" max={songDuration} onChange={updateProgress} />
                </div>            
                <span>  {formatTime(songDuration)}</span>
            </div>
            <div className="audio-player">
                <audio 
                    ref={ audioRef } 
                    src={`./music/${currentSongList[songSelection].location}/${currentSongList[songSelection].file}`} 
                    onLoadedData={handleLoadedData} 
                    onLoadedMetadata={getDuration} 
                    onEnded={handleEnd}
                    autoPlay={isLoaded}
                />
            </div>
        </div>
    )
}

export default AudioPlayer